



























































































import { Component } from 'vue-property-decorator';
import ResourcesToolbar from './ResourcesToolbar.vue';

@Component({
  components: {
    ResourcesToolbar
  }
})
export default class ResourcesBottomToolbar extends ResourcesToolbar {
}
